/* use react context to share share url, headers, and other data between components */
import React from 'react';

export const ApiContext = React.createContext({
  urlParams: {},
  defaultBody: {},
  headers: {},
  setUrlParams: (urlParams: any) => {},
  setDefaultBody: (defaultBody: any) => {},
  setHeaders: (headers: any) => {}
});
