import { useEffect, useState } from 'react';
import { Button } from '@mui/material';

const IdleModalContent = ({
  onStillHere,
  onExit,
  getRemainingTime
}: {
  onStillHere: () => void;
  onExit: () => void;
  getRemainingTime: () => number;
}) => {
  const [countdown, setCountdown] = useState<number>(
    Math.ceil(getRemainingTime() / 1000)
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setCountdown(Math.ceil(getRemainingTime() / 1000));
    }, 1000);
    return () => clearInterval(interval);
  }, [getRemainingTime]);

  return (
    <div
      style={{
        minHeight: '200px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
      }}
    >
      <div>
        <p>Are you still there?</p>
        <p>This page will refresh in {countdown} seconds.</p>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-around' }}>
        <Button onClick={onStillHere}>Yes, I'm Still Here</Button>
        <Button onClick={onExit}>No, Exit</Button>
      </div>
    </div>
  );
};

export default IdleModalContent;
