import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ReturnScreen from './common/ReturnScreen';
import SetupForm from './common/SetupForm';
import SignaturePdf from './signature/SignaturePdf';
import SignatureRender from './signature/SignatureRender';
import { createTheme, styled } from '@mui/material';
import { ApiContext } from './context/api-context';
import { AppType } from './types/App';
import MobileCheckInSuccess from './common/success/MobileCheckInSuccess';

// get URL params

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536
    }
  }
});

const Root = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('xs')]: {
    // overflowY: 'scroll'
  },
  [theme.breakpoints.up('md')]: {},
  [theme.breakpoints.up('lg')]: {
    zoom: 2.0
  }
}));

// apiContext element

// @ts-ignore
root.render(
  <Root>
    <ApiContext.Provider
      value={{
        setUrlParams: (urlParams: any) => {
          // get url params
          let search = window.location.search;
          let searchParams = new URLSearchParams(search);
          let account_id = searchParams.get('acc') || '';
          let facility_id = searchParams.get('fac') || '';

          // set url params
          urlParams.account_id = account_id;
          urlParams.facility_id = facility_id;
        },
        setDefaultBody: (defaultBody: any) => {},
        setHeaders: (headers: any) => {},
        urlParams: {},
        defaultBody: {},
        headers: {}
      }}
    />
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/p/*" element={<App type={AppType.STATIC} />} />
        <Route path="/a/*" element={<App type={AppType.SCROLL_STATIC} />} />
        <Route path="/w/*" element={<App type={AppType.WORKFLOW} />} />
        <Route path="/bol/*" element={<App type={AppType.BOL} />} />
        <Route path="/return" element={<ReturnScreen />} />
        <Route path="/android_init" element={<SetupForm />} />
        <Route path="/bol_pdf/*" element={<SignaturePdf directLink={true} />} />
        <Route path="/signature_render" element={<SignatureRender />} />
        <Route path="/qr/:b64LinkCode" element={<App type={AppType.LINK} />} />
        <Route path="/success/:b64LinkCode" element={<MobileCheckInSuccess directLink={true}/>} />
      </Routes>
    </BrowserRouter>
  </Root>
  // <React.StrictMode>
  //   <App />
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
