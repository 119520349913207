import { Button, Paper } from '@mui/material';
import React from 'react';

declare var android: Android;
class ReturnScreen extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.ReturnToAndroid = this.ReturnToAndroid.bind(this);
  }

  ReturnToAndroid() {
    // @ts-ignore
    if (window.Android) {
      // @ts-ignore
      window.Android.setFormComplete();
      // @ts-ignore
      window.Android.onBackFromJs();
    }
  }
  render() {
    return (
      <>
        <div className="App">
          <header>
            <h1>Trailer Clean and Load Form Complete</h1>
            <Button key="back" onClick={this.ReturnToAndroid} variant="outlined">
              Return to Workflow
            </Button>
          </header>
        </div>
        {/* <Paper>
                    
                </Paper> */}
      </>
    );
  }
}

export default ReturnScreen;
