import { Button, Grid, Paper } from '@mui/material';
import React from 'react';
import { Box } from '@mui/system';

class SignatureRender extends React.Component<any, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      imageData: [],
      jobId: '',
      jobReady: false,
      show_return: !this.props.directLink,
      show_pdf_not_found: false
    };
  }

  render() {
    // create grid from query string name and values
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const signatures = JSON.parse(atob(params.get('data') as string));

    const signature_elemnts: any = [];

    // each signature has name and value pairs as properties
    signatures.forEach((signature: any, key: string) => {
      // each name and value pair is added to the columns and rows arrays
      const columns: any = [];
      const rows: any = [];
      Object.keys(signature['data']).forEach((key: string) => {
        columns.push(key);
        rows.push(signature['data'][key]);
      });

      console.log('columns', columns);
      console.log('rows', rows);
      function titleCase(str: string) {
        return str
          .toLowerCase()
          .split(' ')
          .map(function (word) {
            return word.replace(word[0], word[0].toUpperCase());
          })
          .join(' ');
      }
      signature_elemnts.push(
        <Grid item xs={12}>
          <Paper style={{ padding: 40 }}>
            <Grid key={key} container direction="row">
              <Grid item xs={6}>
                {columns.map((column: any, index: any) => {
                  return (
                    <Grid key={index} container direction="row">
                      <Grid item xs={6} textAlign={'right'} style={{ paddingRight: 10 }}>
                        {titleCase(column.replace(/_/g, ' '))}:
                      </Grid>
                      <Grid item xs={6} textAlign={'left'}>
                        {rows[index]}
                      </Grid>
                    </Grid>
                  );
                })}
              </Grid>
              <Grid item xs={6}>
                <Grid container textAlign={'center'}>
                  <Grid item xs={6}>
                    <Box
                      component="img"
                      sx={{
                        height: 233,
                        width: 350,
                        maxHeight: { xs: 233, md: 167 },
                        maxWidth: { xs: 350, md: 250 }
                      }}
                      alt="signature"
                      src={signature.image_url}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      );
    });

    // base64 decode image url value

    const image_url = atob(params.get('image_url') as string);

    // set style

    return (
      <div>
        <Grid
          container
          xs={12}
          // justifyContent="center"
          // alignItems="center"
          spacing={2}
        >
          {signature_elemnts}
        </Grid>
      </div>
    );
  }
}

export default SignatureRender;
