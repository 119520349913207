export enum ProcessState {
  INIT,
  INIT_WIFI,
  INIT_BROWSER,
  PHONE,
  VERIFY,
  FINDAPPOINTMENT,
  LOAD,
  RESPONSE,
  PREEXIT,
  EXIT,
  BADNETWORK,
  NOTFOUND,
  NOEXIT,
  INSPECT,
  RESPONSE_CONFIRM,

  DEBUG1,
  DEBUG2,
  DEBUG3,
  DEBUG4,
  LOAD_TYPE,
  PRE_REGISTER,
  ASSIGNED,
  VALIDATE_SEAL,
  DRIVER_SIGNATURE,
  AUTH_TYPE,
  AUTO_CHECKIN,
  LANGUAGE_SELECTION = 111,
  DRIVER_LOAD_VALIDATION = 26,

  BOL_KIOSK = 1998,
  DRIERS_LICENSE = 1999,
  BOL_SEAL = 2000,
  REEFER_TEMP = 2001,
  REEFER_SETPOINT = 2002,
  REEFER_FUEL_GAUGE = 2003,
  SEAL_TAG_NUMBER = 2004,

  AUTO_SIGN_IN = 27,
  LANDING = 28,
  AUTO_SIGN_IN_POST_MFA = 29,
  AUTH_TYPE_MOBILE = 30
}

export enum OCRProcessState {
  BOL_SEAL = 0,
  REEFER_TEMP = 1,
  REEFER_SETPOINT = 2,
  SEAL_TAG_INTACT = 3,
  SEAL_TAG_NUMBER = 4
}

export enum WORKFLOWS {
  INIT = 0,
  DRIVER = 1
}

export enum DRIVER_WORKFLOWS {
  INIT = 0,
  CHECK_IN = 1,
  CHECK_OUT = 2,
  PRE_REGISTER = 3,
  ASSIGN = 4,
  BOL = 5,
  CHECK_IN_OCR = 6,
  BOL_FR_ID = 7,
  CHECK_IN_3 = 8,
  MOBILE_CHECK_IN = 9,
  INBOUND_CHECKIN = 10,
  OUTBOUND_CHECKIN = 11,
  INBOUND_CHECKOUT = 12,
  OUTBOUND_CHECKOUT = 13
}

export enum RESPONSE_CODES {
  BAD_REQUEST = 400,
  OK = 200,
  NOT_FOUND = 404,
  UNPROCESSED = 422,
  ACCEPTED = 202,
  CREATED = 201,
  CONFLICT = 409,
  REDIRECT = 302,
  ERROR = 500,
  UNAUTHORIZED = 401,
  LANG = 210
}

// ## NOTE keep track of form Ids

export const WORKFLOW_DEF = {
  [WORKFLOWS.INIT]: {},

  [WORKFLOWS.DRIVER]: {
    // [DRIVER_WORKFLOWS.INIT]: [
    //     {
    //         state: ProcessState.INIT_BROWSER,
    //         fork: [
    //             { next: ProcessState.LOAD_TYPE, responseCodes: [RESPONSE_CODES.OK] },
    //             { next: ProcessState.RESPONSE_CONFIRM, responseCodes: [RESPONSE_CODES.ACCEPTED] },
    //             { next: ProcessState.RESPONSE, responseCodes: [RESPONSE_CODES.CREATED] },
    //         ],
    //
    //         next: ProcessState.PHONE
    //     }
    // ],
    [DRIVER_WORKFLOWS.CHECK_IN]: [
      {
        state: ProcessState.INIT_BROWSER,
        fork: [
          { next: ProcessState.LOAD, responseCodes: [RESPONSE_CODES.OK] },
          {
            next: ProcessState.RESPONSE_CONFIRM,
            responseCodes: [RESPONSE_CODES.ACCEPTED]
          }
        ],
        next: ProcessState.VERIFY
      },
      {
        state: ProcessState.VERIFY,
        fork: [
          {
            next: ProcessState.LOAD_TYPE,
            responseCodes: [RESPONSE_CODES.UNPROCESSED]
          }
        ],
        next: ProcessState.INIT_BROWSER
      },

      {
        state: ProcessState.LOAD,
        fork: [
          {
            next: ProcessState.NOTFOUND,
            responseCodes: [RESPONSE_CODES.ERROR, RESPONSE_CODES.CONFLICT]
          }
        ],
        next: ProcessState.RESPONSE_CONFIRM
      },

      {
        state: ProcessState.RESPONSE_CONFIRM,
        fork: [
          {
            next: ProcessState.NOTFOUND,
            responseCodes: [RESPONSE_CODES.ERROR, RESPONSE_CODES.CONFLICT]
          }
        ],
        next: ProcessState.RESPONSE
      }
    ],
    [DRIVER_WORKFLOWS.CHECK_IN_OCR]: [
      {
        state: ProcessState.INIT_BROWSER,
        fork: [
          { next: ProcessState.LOAD, responseCodes: [RESPONSE_CODES.OK] },
          {
            next: ProcessState.RESPONSE_CONFIRM,
            responseCodes: [RESPONSE_CODES.ACCEPTED]
          }
        ],
        next: ProcessState.VERIFY
      },
      {
        state: ProcessState.VERIFY,
        fork: [
          {
            next: ProcessState.LOAD_TYPE,
            responseCodes: [RESPONSE_CODES.UNPROCESSED]
          }
        ],
        next: ProcessState.INIT_BROWSER
      },

      {
        state: ProcessState.LOAD,
        fork: [
          {
            next: ProcessState.NOTFOUND,
            responseCodes: [RESPONSE_CODES.ERROR, RESPONSE_CODES.CONFLICT]
          }
        ],
        next: ProcessState.RESPONSE_CONFIRM
      },

      {
        state: ProcessState.RESPONSE_CONFIRM,
        fork: [
          {
            next: ProcessState.NOTFOUND,
            responseCodes: [RESPONSE_CODES.ERROR, RESPONSE_CODES.CONFLICT]
          }
        ],
        next: ProcessState.BOL_SEAL
      },
      {
        state: ProcessState.BOL_SEAL,
        fork: [],
        next: ProcessState.SEAL_TAG_NUMBER
      },

      {
        state: ProcessState.SEAL_TAG_NUMBER,
        fork: [],
        next: ProcessState.REEFER_TEMP
      },
      {
        state: ProcessState.REEFER_TEMP,
        fork: [],
        next: ProcessState.REEFER_FUEL_GAUGE
      },

      {
        state: ProcessState.REEFER_FUEL_GAUGE,
        fork: [],
        next: ProcessState.RESPONSE
      }
    ],
    [DRIVER_WORKFLOWS.CHECK_IN_3]: [
      {
        state: ProcessState.INIT_BROWSER,
        fork: [],
        next: ProcessState.AUTH_TYPE
      },
      {
        state: ProcessState.AUTH_TYPE,
        fork: [
          {
            next: ProcessState.VERIFY,
            responseCodes: [RESPONSE_CODES.UNAUTHORIZED, RESPONSE_CODES.NOT_FOUND]
          }
        ],
        next: ProcessState.DRIVER_LOAD_VALIDATION
      },
      {
        state: ProcessState.DRIVER_LOAD_VALIDATION,
        fork: [],
        next: ProcessState.RESPONSE_CONFIRM
      },
      {
        state: ProcessState.RESPONSE_CONFIRM,
        fork: [
          {
            next: ProcessState.NOTFOUND,
            responseCodes: [RESPONSE_CODES.ERROR, RESPONSE_CODES.CONFLICT]
          }
        ],
        next: ProcessState.EXIT
      }
    ],
    [DRIVER_WORKFLOWS.CHECK_OUT]: [
      {
        state: ProcessState.INIT_BROWSER,
        fork: [
          {
            next: ProcessState.VERIFY,
            responseCodes: [RESPONSE_CODES.NOT_FOUND]
          },
          {
            next: ProcessState.NOEXIT,
            responseCodes: [RESPONSE_CODES.BAD_REQUEST, RESPONSE_CODES.OK]
          }
        ],
        next: ProcessState.EXIT
      },
      {
        state: ProcessState.VERIFY,
        fork: [],
        next: ProcessState.INIT_BROWSER
      }
    ],
    [DRIVER_WORKFLOWS.PRE_REGISTER]: [
      {
        state: ProcessState.VERIFY,
        fork: [],
        next: ProcessState.PRE_REGISTER
      }
    ],
    [DRIVER_WORKFLOWS.ASSIGN]: [
      {
        state: ProcessState.INIT_BROWSER,
        fork: [
          { next: ProcessState.ASSIGNED, responseCodes: [RESPONSE_CODES.OK] },
          {
            next: ProcessState.NOEXIT,
            responseCodes: [RESPONSE_CODES.UNAUTHORIZED]
          }
        ]
      }
    ],
    [DRIVER_WORKFLOWS.BOL]: [
      {
        state: ProcessState.INIT_BROWSER,
        fork: [
          { next: ProcessState.LOAD, responseCodes: [RESPONSE_CODES.OK] },
          {
            next: ProcessState.VALIDATE_SEAL,
            responseCodes: [RESPONSE_CODES.ACCEPTED]
          },
          { next: ProcessState.VERIFY, responseCodes: [RESPONSE_CODES.LANG] }
        ],
        next: ProcessState.LANGUAGE_SELECTION
      },
      {
        state: ProcessState.LANGUAGE_SELECTION,
        fork: [],
        next: ProcessState.VERIFY
      },

      {
        state: ProcessState.VERIFY,
        fork: [
          {
            next: ProcessState.LOAD_TYPE,
            responseCodes: [RESPONSE_CODES.UNPROCESSED]
          }
        ],
        next: ProcessState.INIT_BROWSER
      },

      {
        state: ProcessState.LOAD,
        fork: [
          {
            next: ProcessState.NOTFOUND,
            responseCodes: [RESPONSE_CODES.ERROR, RESPONSE_CODES.CONFLICT]
          }
        ],
        next: ProcessState.VALIDATE_SEAL
      },

      {
        state: ProcessState.VALIDATE_SEAL,
        fork: [
          {
            next: ProcessState.NOTFOUND,
            responseCodes: [RESPONSE_CODES.ERROR, RESPONSE_CODES.CONFLICT]
          }
        ],
        next: ProcessState.DRIVER_SIGNATURE
      },
      {
        state: ProcessState.DRIVER_SIGNATURE,
        fork: [],
        next: ProcessState.RESPONSE
      }
    ],
    [DRIVER_WORKFLOWS.BOL_FR_ID]: [
      {
        state: ProcessState.INIT_BROWSER,
        fork: [
          // { next: ProcessState.LOAD, responseCodes: [RESPONSE_CODES.OK] },
          {
            next: ProcessState.VALIDATE_SEAL,
            responseCodes: [RESPONSE_CODES.ACCEPTED]
          }
          // { next: ProcessState.VERIFY, responseCodes: [RESPONSE_CODES.LANG] },
        ],
        next: ProcessState.VALIDATE_SEAL
      },

      {
        state: ProcessState.VERIFY,
        fork: [
          {
            next: ProcessState.NOTFOUND,
            responseCodes: [RESPONSE_CODES.ERROR, RESPONSE_CODES.NOT_FOUND]
          }
        ],
        next: ProcessState.DRIVER_SIGNATURE
      },

      {
        state: ProcessState.VALIDATE_SEAL,
        fork: [
          {
            next: ProcessState.VALIDATE_SEAL,
            responseCodes: [RESPONSE_CODES.UNAUTHORIZED]
          },
          {
            next: ProcessState.AUTO_CHECKIN,
            responseCodes: [RESPONSE_CODES.CREATED]
          }
        ],
        next: ProcessState.AUTH_TYPE
      },
      {
        state: ProcessState.AUTH_TYPE,
        fork: [
          {
            next: ProcessState.AUTH_TYPE,
            responseCodes: [RESPONSE_CODES.UNAUTHORIZED, RESPONSE_CODES.NOT_FOUND]
          }
        ],
        next: ProcessState.AUTO_CHECKIN
      },

      {
        state: ProcessState.AUTO_CHECKIN,
        fork: [
          {
            next: ProcessState.AUTH_TYPE,
            responseCodes: [RESPONSE_CODES.UNAUTHORIZED]
          }
        ],
        next: ProcessState.DRIVER_SIGNATURE
      },

      {
        state: ProcessState.DRIVER_SIGNATURE,
        fork: [],
        next: ProcessState.RESPONSE
      }
    ],
    [DRIVER_WORKFLOWS.MOBILE_CHECK_IN]: [
      {
        state: ProcessState.AUTO_SIGN_IN,
        fork: [
          {
            next: ProcessState.AUTH_TYPE_MOBILE,
            responseCodes: [RESPONSE_CODES.UNAUTHORIZED]
          }
        ],
        next: ProcessState.DRIVER_LOAD_VALIDATION
      },
      {
        state: ProcessState.LANDING,
        fork: [],
        next: ProcessState.DRIVER_LOAD_VALIDATION
      },
      {
        state: ProcessState.AUTH_TYPE_MOBILE,
        fork: [
          {
            next: ProcessState.VERIFY,
            responseCodes: [RESPONSE_CODES.UNAUTHORIZED, RESPONSE_CODES.NOT_FOUND]
          }
        ],
        next: ProcessState.DRIVER_LOAD_VALIDATION
      },
      {
        state: ProcessState.DRIVER_LOAD_VALIDATION,
        fork: [],
        next: ProcessState.RESPONSE_CONFIRM
      },
      {
        state: ProcessState.RESPONSE_CONFIRM,
        fork: [
          {
            next: ProcessState.NOTFOUND,
            responseCodes: [RESPONSE_CODES.ERROR, RESPONSE_CODES.CONFLICT]
          }
        ],
        next: ProcessState.EXIT
      }
    ],
    [DRIVER_WORKFLOWS.INBOUND_CHECKIN]: [
      {
        state: ProcessState.AUTO_SIGN_IN,
        fork: [
          {
            next: ProcessState.AUTH_TYPE_MOBILE,
            responseCodes: [RESPONSE_CODES.UNAUTHORIZED]
          }
        ],
        next: ProcessState.DRIVER_LOAD_VALIDATION
      },
      {
        state: ProcessState.LANDING,
        fork: [],
        next: ProcessState.DRIVER_LOAD_VALIDATION
      },
      {
        state: ProcessState.AUTH_TYPE_MOBILE,
        fork: [
          {
            next: ProcessState.VERIFY,
            responseCodes: [RESPONSE_CODES.UNAUTHORIZED, RESPONSE_CODES.NOT_FOUND]
          }
        ],
        next: ProcessState.DRIVER_LOAD_VALIDATION
      },
      {
        state: ProcessState.DRIVER_LOAD_VALIDATION,
        fork: [],
        next: ProcessState.RESPONSE_CONFIRM
      },
      {
        state: ProcessState.RESPONSE_CONFIRM,
        fork: [
          {
            next: ProcessState.NOTFOUND,
            responseCodes: [RESPONSE_CODES.ERROR, RESPONSE_CODES.CONFLICT]
          }
        ],
        next: ProcessState.DRIERS_LICENSE
      },
      {
        state: ProcessState.DRIERS_LICENSE,
        fork: [],
        next: ProcessState.BOL_SEAL
      },

      {
        state: ProcessState.BOL_SEAL,
        fork: [],
        next: ProcessState.BOL_KIOSK
      },

      {
        state: ProcessState.BOL_KIOSK,
        fork: [],
        next: ProcessState.SEAL_TAG_NUMBER
      },

      {
        state: ProcessState.SEAL_TAG_NUMBER,
        fork: [],
        next: ProcessState.REEFER_TEMP
      },
      {
        state: ProcessState.REEFER_TEMP,
        fork: [],
        next: ProcessState.REEFER_FUEL_GAUGE
      },

      {
        state: ProcessState.REEFER_FUEL_GAUGE,
        fork: [],
        next: ProcessState.EXIT
      }
    ],
    [DRIVER_WORKFLOWS.INBOUND_CHECKOUT]: [
      {
        state: ProcessState.AUTO_SIGN_IN,
        fork: [
          {
            next: ProcessState.AUTH_TYPE_MOBILE,
            responseCodes: [RESPONSE_CODES.UNAUTHORIZED]
          }
        ],
        next: ProcessState.DRIVER_LOAD_VALIDATION
      },
      {
        state: ProcessState.LANDING,
        fork: [],
        next: ProcessState.DRIVER_LOAD_VALIDATION
      },
      {
        state: ProcessState.AUTH_TYPE_MOBILE,
        fork: [
          {
            next: ProcessState.VERIFY,
            responseCodes: [RESPONSE_CODES.UNAUTHORIZED, RESPONSE_CODES.NOT_FOUND]
          }
        ],
        next: ProcessState.DRIVER_LOAD_VALIDATION
      },
      {
        state: ProcessState.DRIVER_LOAD_VALIDATION,
        fork: [],
        next: ProcessState.RESPONSE_CONFIRM
      },
      {
        state: ProcessState.RESPONSE_CONFIRM,
        fork: [
          {
            next: ProcessState.NOTFOUND,
            responseCodes: [RESPONSE_CODES.ERROR, RESPONSE_CODES.CONFLICT]
          }
        ],
        next: ProcessState.BOL_SEAL
      },
      {
        state: ProcessState.BOL_SEAL,
        fork: [],
        next: ProcessState.SEAL_TAG_NUMBER
      },

      {
        state: ProcessState.SEAL_TAG_NUMBER,
        fork: [],
        next: ProcessState.REEFER_TEMP
      },
      {
        state: ProcessState.REEFER_TEMP,
        fork: [],
        next: ProcessState.REEFER_FUEL_GAUGE
      },

      {
        state: ProcessState.REEFER_FUEL_GAUGE,
        fork: [],
        next: ProcessState.EXIT
      }
    ]
  }
};

export class WorkflowManager {
  private state: ProcessState;
  private previousState: ProcessState;
  private workflow: any;
  private driverWorkflow: any;
  private boundState: ((state: ProcessState) => {}) | undefined;

  constructor(workflow: WORKFLOWS, driverWorkflow: DRIVER_WORKFLOWS) {
    this.state = ProcessState.INIT_BROWSER;
    this.previousState = ProcessState.INIT_BROWSER;
    this.load(workflow);
    this.setDriverWorkflow(driverWorkflow);
  }

  public load(workflow: WORKFLOWS) {
    this.workflow = WORKFLOW_DEF[workflow];
  }

  public setDriverWorkflow(driverWorkflow: DRIVER_WORKFLOWS) {
    this.driverWorkflow = this.workflow[driverWorkflow];
  }

  public current() {
    return this.state;
  }

  public set(state: ProcessState) {
    this.state = state;
    if (this.boundState) {
      this.boundState(this.state);
    }
  }

  public reset(callback: () => any) {
    this.state = ProcessState.INIT_BROWSER;
    if (this.boundState) {
      this.boundState(this.state);
    }
    this.previousState = this.state;
    callback();
  }

  public bind(f: any) {
    this.boundState = f;
  }

  public forward(response_code: RESPONSE_CODES | undefined) {
    let next = this.state;

    console.log('response_code', response_code);
    console.log('this.state', this.state);
    for (let workflow in this.driverWorkflow) {
      if (this.state === this.driverWorkflow[workflow].state) {
        if (this.driverWorkflow[workflow].fork.length > 0) {
          for (let fork in this.driverWorkflow[workflow].fork) {
            if (
              this.driverWorkflow[workflow].fork[fork].responseCodes.includes(
                response_code
              )
            ) {
              next = this.driverWorkflow[workflow].fork[fork].next;
            }
          }
        }
        if (next === this.state) {
          next = this.driverWorkflow[workflow].next;
        }

        // this.driverWorkflow[workflow]
      }
    }
    if (this.state !== next) {
      this.previousState = this.state;
    }

    this.state = next;

    if (this.boundState) {
      this.boundState(this.state);
    }
    console.log(this.current());
    return this.current();
  }

  public backward() {
    this.state = this.previousState;
    if (this.boundState) {
      this.boundState(this.state);
    }
    return this.current();
  }
}
