import Grid from '@mui/material/Grid';
import {
  AppBar,
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Button,
  FormControl,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  TextField,
  Toolbar,
  Typography
} from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import myImage from './My_WiFi_QR_Code.png';
// import myImage2 from './img.png'
import myImage3 from './wifi-steps.png';
import { Link } from 'react-router-dom';

export enum ProcessState {
  NFC = 1,
  QR = 2,
  QR_OUT = 3
}

function Form() {
  const [page, setPage] = useState(ProcessState.QR);
  let search = window.location.search;
  let searchParams = new URLSearchParams(search);
  let locLink = '';
  let content;
  let location = '';
  let acc = '';

  switch (page) {
    case ProcessState.NFC:
      content = (
        <div>
          <p>Please UNLOCK your screen</p>
          <p>Please tap your phone on the NFC Reader and follow the prompts</p>
          <Box
            component="img"
            sx={{
              height: 350,
              width: 222,
              maxHeight: { xs: 233, md: 350 },
              maxWidth: { xs: 350, md: 300 }
            }}
            alt=""
            // src={myImage2}
          />
          <p>If nothing happens, please click on “Continue”</p>

          <p>Note: Tapping iPhone will not work unless “XXX” App is downloaded</p>
        </div>
      );
      break;
    case ProcessState.QR:
      location = searchParams.get('location') || '';
      acc = searchParams.get('acc') || '';
      if (location === 'EXIT') {
        setPage(ProcessState.QR_OUT);
      }
      locLink = 'https://dg-qa.freightroll.com/?acc=' + acc + '&location=' + location;

      content = (
        <div>
          <Grid sx={{ marginLeft: '5%', textAlign: 'left' }}>
            <h1 style={{ textAlign: 'center' }}>
              To Check-In: Please Scan the QR Code with your phone's camera, click the
              link that pops up and follow the prompts.
            </h1>
          </Grid>

          <div style={{ background: 'white', padding: '8px', height: '30%' }}>
            {/*<QRCode value={locLink}  />*/}
          </div>

          <Grid>
            <p>
              If you can not scan the QR Code, please click the "Use Kiosk" button below.
              If you are still having trouble, please call 555-555-1212
            </p>
          </Grid>
        </div>
      );
      break;
    case ProcessState.QR_OUT:
      location = searchParams.get('location') || '';
      acc = searchParams.get('acc') || '';
      locLink =
        'https://dg-qa.freightroll.com/?acc=' + acc + '&location=' + location + '&co=1';
      content = (
        <div>
          <Grid sx={{ marginLeft: '5%', textAlign: 'left' }}>
            <h1 style={{ textAlign: 'center' }}>
              To Check-Out: Please Scan the QR Code with your phone's camera, click the
              link that pops up and follow the prompts.
            </h1>
          </Grid>

          <div style={{ background: 'white', padding: '8px', height: '30%' }}>
            {/*<QRCode value={locLink}  />*/}
          </div>

          <Grid>
            <p>
              If you can not scan the QR Code, please click the "Use Kiosk" button below.
              If you are still having trouble, please call 555-555-1212
            </p>
          </Grid>
        </div>
      );
      break;
  }

  const nextPage = () => {
    if (page === ProcessState.QR || page === ProcessState.QR_OUT) {
      window.location.href = locLink + '&reset=1';
    } else {
      setPage(page + 1);
    }
  };

  const prevPage = () => {
    if (page === ProcessState.NFC) {
    } else {
      setPage(page - 1);
    }
  };

  const styles = {
    root: {
      flexGrow: 1
    },
    appbar: {
      alignItems: 'center'
    }
  };

  let backButton;
  if ([ProcessState.NFC, ProcessState.QR, ProcessState.QR_OUT].includes(page)) {
  } else {
    backButton = (
      <Typography variant="h6" component="div">
        <Button onClick={prevPage} variant="contained">
          Back
        </Button>
      </Typography>
    );
  }
  const [auth, setAuth] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAuth(event.target.checked);
  };

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div>
      <AppBar position="static">
        <Toolbar variant="dense">
          {backButton}
          <div>
            <IconButton
              size="small"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              EN
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={handleClose}>EN</MenuItem>
              <MenuItem onClick={handleClose}>ES</MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>

      <div>
        {content}
        <AppBar position="fixed" color="primary" sx={{ top: 'auto', bottom: 0 }}>
          <Toolbar
            variant="dense"
            sx={{
              top: 'auto',
              bottom: 0,
              display: 'flex',
              justifyContent: 'center',
              width: '100%'
            }}
          >
            <Typography variant="h6" component="div">
              <Button onClick={nextPage} variant="contained">
                USE KIOSK
              </Button>
            </Typography>
          </Toolbar>
        </AppBar>
      </div>
    </div>
  );
}

function View() {
  return <div className="App">An error occurred. Please try again. If the error persists, please seek assistance by talking to the facility manager, looking at any posted instructions or going into the dock office to use the kiosk.</div>;
}

export default View;
