import React, { useState, useEffect } from 'react';
import {Button, Grid, SvgIcon} from '@mui/material';
import { useParams } from 'react-router-dom';
import QRCode from "react-qr-code";

const MobileCheckInSuccess = ({ directLink }: { directLink?: boolean}) => {
  const [frId, setFrId] = useState('');
  const [loadNumber, setLoadNumber] = useState('');
  const [fromLink, setFromLink] = useState(directLink);
  const [loading, setLoading] = useState(false);
  const [workflow, setWorkflow] = useState(''); // Assuming the response includes workflow
  const { b64LinkCode } = useParams<{ b64LinkCode?: string }>();
  const [deviceID, setDeviceID] = useState('');

  useEffect(() => {
    // get deviceID from session storage otherwise generate an uuid for it and save it to session storage
    let device_id = localStorage.getItem('deviceID');
    if (!device_id) {
      device_id = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        const r = (Math.random() * 16) | 0,
          v = c == 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      });
      localStorage.setItem('deviceID', device_id);
    }
    setDeviceID(device_id);
  }, []);

  useEffect(() => {
    const fetchLinkObj = async () => {
      if (b64LinkCode) {
        setLoading(true);
        try {
          const response = await fetch(
                `${process.env.REACT_APP_base}/api/v1/link/${b64LinkCode}`,
                {
                    method: "GET",
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        'Device-Id': deviceID
                    }
                }
            );
          const data = await response.json();
          if (data.fr_id !== "temp") {
            setFrId(data.fr_id); // Assuming the response includes fr_id
          }
          setWorkflow(data.workflow); // Assuming the response includes workflow
          setLoadNumber(data.load_number); // Assuming the response includes load_number
          setFromLink(true);
        } catch (error) {
          console.error('Failed to fetch link object:', error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchLinkObj();
  }, [b64LinkCode]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div style={{
        display: 'flex',
        justifyContent: 'center',
        margin: '16px'}} >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div>
            <h1>Thank You!</h1>
          </div>
          <p>Check In Complete for Shipment #: {loadNumber}</p>
          {!!frId && <p>Your FreightRoll ID is: {frId}</p>}

          <p style={{ textTransform: 'uppercase' }}>
            To open the gate please scan the qr code below
          </p>

          {workflow === 'checkin_inbound/' && (
              <div>
                <SvgIcon style={{ color: 'red', fontSize: '1000%' }}>
                  <path d="M0 0h24v24H0z" fill="none"/>
                  <path d="M18.3 5.7L12 12l-6.3-6.3-1.4 1.4L10.6 12l-6.3 6.3 1.4 1.4L12 13.4l6.3 6.3 1.4-1.4L13.4 12l6.3-6.3z"/>
                </SvgIcon>
                <QRCode value={window.location.href}> </QRCode>
                <p style={{ textTransform: 'uppercase', color: 'red' }}>
                  UNFORTUNATELY THERE WAS AN ISSUE WITH YOUR CHECK-IN. ONCE YOU HAVE ENTERED THE GATE, PLEASE PROCEED TO THE DOCK OFFICE TO SHOW THEM THIS RECEIPT
                </p>
              </div>
          )}

          {workflow === 'checkin4/' && (
              <div>
                <SvgIcon style={{ color: 'green', fontSize: '1000%' }}>
                  <path d="M0 0h24v24H0z" fill="none"/>
                  <path d="M9 16.2l-4.2-4.2-1.4 1.4L9 19l12-12-1.4-1.4L9 16.2z"/>
                </SvgIcon>

                <QRCode value={window.location.href}></QRCode>
                <p>
                  You will receive information about where to drop your empty, where to dock your truck and/or where to pick up your loaded trailer via text message
                </p>
              </div>
          )}

        </Grid>
      </Grid>
    </div>
  );
};

export default MobileCheckInSuccess;