import React, { useContext } from 'react';
import { Grid } from '@mui/material';
import Tag404 from '../custom/default/Tag404';
// props
interface PageProps {
  pages: any[];
  theme: any;
  setBarButton: any;
}

const Page = ({ pages, theme, setBarButton }: PageProps) => {
  // get page path
  let page = window.location.pathname.split('/')[2];
  // find the page
  page = page || 'home';

  let pageMatch = pages.find((p: any) => {
    return p.page_code === page;
  });

  const loading = <div>Loading...</div>;
  if (!pageMatch) {
    return <Tag404 />;
  }

  const CustomTag = React.lazy(() =>
    import(`../custom/${theme.name}/${pageMatch.page_component}`).catch((e) => {
      return import(`../custom/default/${pageMatch.page_component}`).catch((e) => {
        return <Tag404 />;
      });
    })
  );

  return (
    <Grid>
      <React.Suspense fallback={loading}>
        <CustomTag setBarButton={setBarButton} />
      </React.Suspense>
    </Grid>
  );
};

export default Page;
