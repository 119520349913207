import React, { useState, useEffect } from 'react';

// making a custom react hook like this

export default function Config() {
  // get account id from query string parameter acc
  let search = window.location.search;
  let searchParams = new URLSearchParams(search);
  let account_id = searchParams.get('acc') || '';

  return fetch(process.env.REACT_APP_base + '/config.json')
    .then((response) => response.json())
    .then((data) => {
      console.log(data);
      // find the identity
      let identityMatch = data.configs.find((config: any) => {
        return config.identity.account_id === account_id;
      });
      if (!identityMatch) {
        identityMatch = data.configs.find((config: any) => {
          return config.identity.account_id === '*';
        });
      }
      return identityMatch || { pages: [], theme: {} };
    });
}
