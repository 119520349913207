import { Button } from '@mui/material';
import React from 'react';

class SignaturePdf extends React.Component<any, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      imageData: [],
      jobId: '',
      jobReady: false,
      show_return: !this.props.directLink,
      show_pdf_not_found: false,
      bol_id: ''
    };
  }

  // trying something new
  async componentDidMount() {
    var baseUrl = '';
    var startPdfCreationRequestUrl = '';
    if (this.props.directLink) {
      const { protocol, hostname, port } = window.location;
      baseUrl = `${protocol}//${hostname}${port ? `:${port}` : ''}`;
      var bol_id = this.getBolIdFromUrl(window.location.href);
      this.setState({
        bol_id: bol_id
      });
      startPdfCreationRequestUrl = baseUrl + '/api/v1/bols/' + bol_id + '/get_pdf';
    } else {
      baseUrl = this.props.base_url;
      startPdfCreationRequestUrl = baseUrl + this.props.route;
    }
    const getJobIdResponse = await fetch(startPdfCreationRequestUrl, {
      method: 'GET'
    });
    const getJobIdData = await getJobIdResponse.json();
    this.setState(
      {
        jobId: getJobIdData.result_id
      },
      () => {
        console.log(this.state.jobId);
        if (this.state.jobId === 'n/a') {
          this.setState({
            show_pdf_not_found: true
          });
        } else {
          this.setState({
            show_pdf_not_found: false
          });
          checkJobStatus();
        }
      }
    );

    const checkJobStatus = async () => {
      const jobId = this.state.jobId;
      const checkJobStatusUrl = baseUrl + '/api/v1/get_pdf_from_worker/' + jobId;
      const statusResponse = await fetch(checkJobStatusUrl, { method: 'GET' });
      const statusData = await statusResponse.json();
      if (statusData['ready']) {
        this.setState({
          jobReady: true,
          imageData: statusData['value']
        });
      } else {
        setTimeout(checkJobStatus, 500);
      }
    };
    // checkJobStatus()
  }

  getBolIdFromUrl(url: string): string | null {
    const regex = /bol_pdf\/(\d+)(\/)?/;
    const match = url.match(regex);
    if (match && match[1]) {
      return match[1];
    } else {
      return null;
    }
  }

  WithReturn(imageData: any) {
    if (this.state.jobReady) {
      return (
        <div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              margin: '16px'
            }}
          >
            <Button variant="contained" onClick={this.props.handleCtaClickedCallback}>
              Return to Signature
            </Button>
          </div>
          <div>
            {imageData.map((base64Img: string, index: number) => (
              <div key={index} style={{ border: '1px solid black', margin: '16px' }}>
                <img
                  src={`data:img/png;base64,${base64Img}`}
                  alt={`Page ${index + 1}`}
                  style={{
                    width: '90%',
                    height: 'auto'
                  }}
                />
              </div>
            ))}
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              margin: '16px'
            }}
          >
            <Button variant="contained" onClick={this.props.handleCtaClickedCallback}>
              Return to Signature
            </Button>
          </div>
          <h1>Loading...</h1>
        </div>
      );
    }
  }

  WithoutReturn(imageData: any) {
    if (this.state.jobReady) {
      return (
        <div>
          <div>
            {imageData.map((base64Img: string, index: number) => (
              <div key={index} style={{ border: '1px solid black', margin: '16px' }}>
                <img
                  src={`data:img/png;base64,${base64Img}`}
                  alt={`Page ${index + 1}`}
                  style={{
                    width: '90%',
                    height: 'auto'
                  }}
                />
              </div>
            ))}
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <h1>Loading...</h1>
        </div>
      );
    }
  }

  render() {
    const imageData = this.state.imageData;
    if (this.state.show_pdf_not_found || this.state.bol_id === null) {
      if (this.state.show_return) {
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              margin: '16px'
            }}
          >
            <Button variant="contained" onClick={this.props.handleCtaClickedCallback}>
              Return to Signature
            </Button>
            <h3>
              BOL not found. Click to return to the signature page. Please contact support
              at support@freightroll.com to gain access
            </h3>
          </div>
        );
      } else {
        <div style={{ display: 'flex', justifyContent: 'center', margin: '16px' }}>
          <h3>
            BOL not found. Please contact support at support@freightroll.com to gain
            access
          </h3>
        </div>;
      }
    } else if (this.state.show_return) {
      return this.WithReturn(imageData);
    } else {
      return this.WithoutReturn(imageData);
    }
  }
}

export default SignaturePdf;
