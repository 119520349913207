import { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { Modal } from '@mui/material';

interface ModalProps {
  open: boolean;
  children: any;
  onClose?: any;
  styles?: any;
}

const ModalWrapper = ({ open, children, onClose, styles }: ModalProps) => {
  if (!styles) {
    styles = {
      width: 400,
      backgroundColor: '#fff',
      border: '2px solid #000',
      boxShadow: '5px 5px 5px #000',
      padding: 20,
      minHeight: 200
    };
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      <div style={styles}>{children}</div>
    </Modal>
  );
};

export default ModalWrapper;
