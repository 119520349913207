import { createContext } from 'react';

export const DriverContext = createContext({});

export function DriverContextHOC(Component) {
  return function contextComponent(props) {
    return (
      <DriverContext.Consumer>
        {(driverContext) => <Component {...props} driverContext={driverContext} />}
      </DriverContext.Consumer>
    );
  };
}
