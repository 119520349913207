import { Button, Grid, Icon } from '@mui/material';
import React from 'react';
import CheckIcon from '@mui/icons-material/Check';
import QRCode from "react-qr-code";
class Bol extends React.Component<any, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      imageData: [],
      jobId: '',
      jobReady: false,
      show_return: false,
      show_pdf_not_found: false,
      bol_id: '',
      bol_details: {},
      driver_details: {}
    };
  }
  checkJobStatus = async () => {
    const jobId = this.state.jobId;
    const checkJobStatusUrl =
        this.props.base_url + '/api/v1/get_pdf_from_worker/' + jobId;
    const statusResponse = await fetch(checkJobStatusUrl, {method: 'GET'});
    const statusData = await statusResponse.json();
    if (statusData['ready']) {
      this.setState({
        jobReady: true,
        imageData: statusData['value'],
        show_pdf_not_found: false
      });
    } else {
      setTimeout(this.checkJobStatus, 1500);
    }
  }

  checkDeviceId = async () => {
    await new Promise((resolve) => {
      (function waitForDeviceId() {
        if (this.props.device_id !== '') {
          resolve(true);
        } else {
          setTimeout(waitForDeviceId.bind(this), 500);
        }
      }).call(this);
    });
  };
  // trying something new
  async componentDidMount() {

    // wait for this.props.device_id to be not null then execute below

    await this.checkDeviceId();

    var baseUrl = '';
    var startPdfCreationRequestUrl = '';
    const link_object = await this.getBolLinkFromUrl(window.location.href);

    // fetch shipment details by account id and bol id from link_object
    const shipmentDetailsResponse = await fetch(
      this.props.base_url + '/api/v1/bols/' + link_object['bol_id'] + '/details',
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Device-Id': this.props.device_id
        }
      }
    );
    const shipmentDetailsData = await shipmentDetailsResponse.json();
    this.setState({
      bol_details: shipmentDetailsData
    });

    const driverDetailsResponse = await fetch(this.props.base_url + '/api/v1/whoami', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Device-Id': this.props.device_id
      }
    });
    const driverDetailsData = await driverDetailsResponse.json();
    this.setState({
      driver_details: driverDetailsData
    });

    startPdfCreationRequestUrl =
      this.props.base_url + '/api/v1/bols/' + link_object['bol_id'] + '/get_pdf';

    const getJobIdResponse = await fetch(startPdfCreationRequestUrl, {
      method: 'GET'
    });
    const getJobIdData = await getJobIdResponse.json();
    this.setState(
      {
        jobId: getJobIdData.result_id
      },
      () => {
        console.log(this.state.jobId);
        if (this.state.jobId === 'n/a') {
          this.setState({
            show_pdf_not_found: true
          });
        } else {
          this.setState({
            show_pdf_not_found: false
          });
          this.checkJobStatus();
        }
      }
    );


    };
    // checkJobStatus()


  async getBolLinkFromUrl(url: string): Promise<any> {
    const regex = /bol\/(.*)/;
    const match = url.match(regex);
    if (match && match[1]) {
      const link_code = match[1];
      // validate that link_code is base64
      const response = await fetch(this.props.base_url + '/api/v1/link/' + link_code, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Device-Id': this.props.device_id
        }
      }).then((response) => response.json());

      return response;
    } else {
      return null;
    }
  }

  WithReturn(imageData: any) {
    if (this.state.jobReady) {
      if (this.state.show_return) {
        return (
          <div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                margin: '16px'
              }}
            >
              <Button
                variant="contained"
                onClick={() => {
                  this.setState({
                    show_return: false
                  });
                }}
              >
                Close
              </Button>
            </div>
            <div>
              {imageData.map((base64Img: string, index: number) => (
                <div key={index} style={{ border: '1px solid black', margin: '16px' }}>
                  <img
                    src={`data:img/png;base64,${base64Img}`}
                    alt={`Page ${index + 1}`}
                    style={{
                      width: '90%',
                      height: 'auto'
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
        );
      } else {
        // show big checkmark before confirm load #
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              margin: '16px'
            }}
          >
            <Grid>
              <Grid>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <CheckIcon sx={{ fontSize: 175, color: 'green' }} />
                </Grid>

                <QRCode value={window.location.href}></QRCode>

                <h2>Confirm Load #: </h2>
                <h1 style={{ margin: '2px', textOverflow: '' }}>
                  {this.state.bol_details['bol']['load_number']}
                </h1>

                <h2>Carrier on BOL: {this.state.bol_details['bol']['carrier_name']}</h2>

                <h3>
                  Your FreightRoll ID: {this.state.driver_details['driver']['fr_id']}
                </h3>
              </Grid>
              <Grid>
                <Button
                  variant="contained"
                  onClick={() => {
                    this.setState({
                      show_return: true
                    });
                  }}
                >
                  Proceed To BOL
                </Button>
              </Grid>
            </Grid>
          </div>
        );
      }
    } else {
      return (
        <div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              margin: '16px'
            }}
          ></div>
          <h1>Loading...</h1>
        </div>
      );
    }
  }

  render() {
    const imageData = this.state.imageData;
    if (this.state.show_pdf_not_found || this.state.bol_id === null) {
      if (this.state.show_return) {
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              margin: '16px'
            }}
          >
            <Button variant="contained" onClick={this.props.handleCtaClickedCallback}>
              Return to Signature
            </Button>
            <h3>
              BOL not found. Click to return to the signature page. Please contact support
              at support@freightroll.com to gain access
            </h3>
          </div>
        );
      } else {
        <div style={{ display: 'flex', justifyContent: 'center', margin: '16px' }}>
          <h3>
            BOL not found. Please contact support at support@freightroll.com to gain
            access
          </h3>
        </div>;
      }
    } else if (this.state.show_return) {
      return this.WithReturn(imageData);
    } else {
      return this.WithReturn(imageData);
    }
  }
}

export default Bol;
